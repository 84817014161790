import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPreHeaderService {

  private _open = new BehaviorSubject<boolean>(false);

  get open$() {
    return this._open.asObservable();
  }

  toggle() {
    this._open.next(!this._open.value);
  }

  close() {
    if (this._open.value) {
      this._open.next(false);
    }
  }
}
